import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Select, Button, message, Collapse } from 'antd';
import ContentHeader from './components/ContentHeader';
import { connect } from 'react-redux'
import { setReportLoading } from './redux'
import axios from 'axios'
import { apiBaseUrl } from './Url'
import Loader from './components/Loader';


const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

function Analytics({ setReportLoading, history, user, report, global }) {
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)
    const [data, setData] = useState([])
    const [mainData, setMainData] = useState([])
    const [total, setTotal] = useState(0)
    // const [subalpineDetails, setSubalpineDetails] = useState([])



    useEffect(() => {
        if (!user.details.type) {
            history.push('/login')
        }

    }, [user.details.type, history]);


    const handleClick = (e) => {
        e.preventDefault();

        if (handleValidation()) {
            getData();
        }
    }

    function handleValidation() {
        let formIsValid = true;

        if (!month) {
            formIsValid = false;
            message.error('Select a month.');
            return formIsValid;
        }

        if (!year) {
            formIsValid = false;
            message.error('Select a year.');
            return formIsValid;
        }


        return formIsValid;
    }

    const getData = () => {

        const start_date = year + "-" + month + "-01";
        const end_date = year + "-" + month + "-31";

        setReportLoading(true)
        axios(
            {
                method: 'POST',
                url: apiBaseUrl + "report/avalanche_danger_characteristic_report_by_date",
                data: { start_date, end_date }
            })
            .then(response => {
                console.log("response", response.data.payload)
                setData(response.data.payload)
                setReportLoading(false)
            })
            .catch(error => {
                const errorMsg = error.message
                setReportLoading(false)
                console.log(errorMsg);
            })
    }

    useEffect(() => {
        if (data && data.length > 0) {
            const regionsArray = ["Front Range", "Dividing Range", "Main range"];
            const areaArray = ["Alpine", "Subalpine"];
            const hazardsArray = global.hazards.filter(function (item) {
                return item.category === "Avalanche danger"
            });
            const charactorArray = global.hazardCharacteristics

            let mainArray = [];
            let totalCount = 0;

            //regions
            regionsArray.map(function (region) {
                const region_ = data.filter(function (el) {
                    return el.region === region
                });

                // areas 
                let areasDetails = [];
                areaArray.map(function (area) {
                    const areas_ = region_.filter(function (area_el) {
                        return area_el.area === area
                    });


                    //hazards
                    let hazardDetails = [];
                    hazardsArray.map(function (hazard, k) {

                        const hazards_ = areas_.filter(function (hazard_el, i) {
                            return hazard_el.hazard === hazard.name
                        });

                        if (hazards_.length > 0) {

                            //characotors
                            let charactorDetails = []
                            charactorArray.map(function (char) {

                                const charactors_ = hazards_.filter(function (char_el) {
                                    return char_el.character === char.name
                                });

                                if (charactors_.length > 0) {
                                    charactorDetails.push({ character: char.name, count: charactors_.length })
                                }
                                return null;
                            });


                            hazardDetails.push({ hazard: hazard.name, count: hazards_.length, charactorDetails })
                        }
                        return null;
                    })

                    areasDetails.push({ area: area, count: areas_.length, hazardDetails })
                    return null;
                });



                mainArray.push({ region: region, count: region_.length, areasDetails })
                totalCount = totalCount + region_.length

                return null;
            })

            setMainData(mainArray)
            setTotal(totalCount)
        }
    }, [data, global])


    const collapsHtml = mainData.map(function (region, i) {
        return (
            <Panel header={"(" + region.count + ") "+ region.region} key={region.region + i}>
                <Collapse>
                    {
                        region.areasDetails.map(function (area, i) {
                            return (
                                <Panel header={" (" + area.count + ") " + area.area } key={region.region + i}>
                                    <Collapse>
                                        {
                                            area.hazardDetails.map(function (hazard, i) {
                                                return (
                                                    <Panel header={"(" + hazard.count + ") "+ hazard.hazard} key={hazard.hazard + i}>
                                                        {
                                                            hazard.charactorDetails.map(function (char, i) {
                                                                return (
                                                                    <div>
                                                                        <span key={char.name + i}>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            {"(" + char.count + ") " + char.character}
                                                                        </span>
                                                                        <br />
                                                                        <br />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </Panel>
                                                )
                                            })
                                        }
                                    </Collapse>
                                </Panel>
                            )
                        })
                    }
                </Collapse>
            </Panel>
        )
    })

    console.log("mainArray", mainData);



    return (
        <div>
            <ContentHeader title="Analytics" />
            <Content style={{ margin: '24px 16px 0' }}>
                <div className="site-layout-background">
                    <Row>

                        <Col md={5} sm={12}>
                        </Col>


                        <Col md={14} sm={24}>
                            <Row>
                                <Col md={24} sm={24} style={{ marginBottom: 20 }}>
                                    <Select style={{ width: 200 }} placeholder="Select Month" onChange={e => setMonth(e)} value={month}>
                                        <Option value="01">January</Option>
                                        <Option value="02">February</Option>
                                        <Option value="03">March</Option>
                                        <Option value="04">April</Option>
                                        <Option value="05">May</Option>
                                        <Option value="06">June</Option>
                                        <Option value="07">July</Option>
                                        <Option value="08">August</Option>
                                        <Option value="09">September</Option>
                                        <Option value="10">October </Option>
                                        <Option value="11">November</Option>
                                        <Option value="12">December</Option>
                                    </Select>
                                    &nbsp;
                                    <Select style={{ width: 200 }} placeholder="Select Year" onChange={e => setYear(e)} value={year}>
                                        <Option value="2021">2021</Option>
                                        <Option value="2022">2022</Option>
                                        <Option value="2023">2023</Option>
                                    </Select>
                                    &nbsp;
                                    <Button type="primary" onClick={e => handleClick(e)}>Okay</Button>
                                </Col>
                            </Row>

                            <Row>
                                <Collapse>
                                    <Panel header={"(" + total + ") Total"} key="1">
                                        <Collapse>
                                            {collapsHtml}
                                            {/* <Panel header="Front" key="2">
                                                <span>Front</span>
                                            </Panel>
                                            <Panel header="Dividing" key="3">
                                                <span>Deviding</span>
                                            </Panel>
                                            <Panel header="Main" key="4">
                                                <span>Deviding</span>
                                            </Panel> */}
                                        </Collapse>
                                    </Panel>
                                </Collapse>
                            </Row>




                        </Col>

                        <Col md={5} sm={12}>
                        </Col>
                    </Row>
                </div>
            </Content>
            {(report.loading ? <Loader title="Loading..." /> : null)}
        </div>
    );
}




const mapStateToProps = state => {
    return {
        report: state.report,
        user: state.user,
        global: state.global
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setReportLoading: (bool) => dispatch(setReportLoading(bool)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
